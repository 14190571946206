import React from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';



const NotFound = ()=> (
<div>
404 - <Link to="/">Page not found</Link>


</div>


);

export default NotFound;