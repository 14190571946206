import React from 'react';
import ReactDOM from 'react-dom';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Contact from './contact';


const ContactUs = ()=> (
 
  <section className='bg-color11'>
  <div className='bgwhite'>
<div className="container">
<div className="row ">

<div className="col-lg-8 col-md-8 col-sm-10 mx-auto pt-3 pb-2"><h3>Peter Stacks Limited</h3>
<div><iframe width="100%" height="500" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20western%20road%20romford+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://www.maps.ie/route-planner.htm">Driving Route Planner</a></div>
    </div>
    <div className="col-lg-4 col-md-4 col-sm-10 mx-auto pt-3 pb-2" >
    <div>
    <h3>Greater London</h3>
    <p>1 Western Court, Western Road<br/>
    Romford<br/>
    RM1 3JR<br/>
    United Kingdom</p>
    <p>T: +44(0) 7908745864<br/>
    F: +44(0) 2075551234
     </p>
     <p>info@peterstacks.co.uk</p>
    </div>
    </div>

</div></div></div>
 
  { /*<section className= "contact pt-5 pb-4">
        
  <div className="container">
  <br/><br/>
  <h2 className="card-title text-center text-white m-bottom-">Ready to get started?</h2>
  <p className="text-center text-white mb-5">Nemo enim psam voluptatem quia voluptas aspernatur blandit tempus.</p>
  <div className="contact_overlay position-absolute"><img src="/images/heade-bottom-shape.png" alt="img" className="img-fluid"/></div>
  <div class="row d-flex justify-content-center"><div class="col-md-8">


  <form className="needs-validation text-center" id="contactForm">
  <div className="form-row ">
  <div className="col-md-6">
  <label for="validationCustom01"></label>
  <input type="text" className="form-control rounded-sm border-0" id="validationCustom01" placeholder="Your name" required="" value=""/>
  <div className="valid-feedback">Looks good!</div></div>
  <div className="col-md-6">
  <label for="validationCustom02"></label>
  <input type="text" className="form-control rounded-sm border-0" id="validationCustom02" placeholder="Last name" required="" value=""/>
  <div className="valid-feedback">Looks good!</div></div>
  <div className="col-md-6">
  <label for="validationCustom03"></label>
  <input type="email" className="form-control rounded-sm border-0" id="validationCustom03" placeholder="Email" required="" value=""/>
  <div className="valid-feedback">Looks good!</div></div>
  <div className="col-md-6">
  <label for="validationCustom04"></label>
  <input type="tel" className="form-control rounded-sm border-0" id="validationCustom04" placeholder="Telephone No" required="" value=""/>
  <div className="valid-feedback">Looks good!</div></div>
  <div className="col-md-12">
  <label for="validationCustom05"></label>
  <select className="form-control rounded-sm border-0">
<option>Specify your need?</option>
</select>
   <div className="valid-feedback">Looks good!</div></div>
  
   <div className="col-md-12">
  <label for="validationCustom05"></label>
  <select className="form-control rounded-sm border-0">
<option>Best time to talk?</option>

</select>
   <div className="valid-feedback">Looks good!</div></div>

   <div className="col-md-12">
  <label for="validationCustom05"></label>
  <select className="form-control rounded-sm border-0">
<option>How did you hear about us?</option>
</select>
   <div className="valid-feedback">Looks good!</div></div>

   <div class="col-md-12">
                        <div class="form-group">
                            <label for="form_message"></label>
                            <textarea id="form_message" name="message" className="form-control" placeholder="Your Message" rows="4" data-error="Please, leave us a message."></textarea>
                            <div className="help-block with-errors"></div>
                        </div>
                    </div>
  </div>
  <button class="btn-success btn-lg wow zoomIn rounded-sm slow mt-5 border-0" type="submit">let's talk</button>
  </form>
  

  </div>
  </div>
  </div>


  
</section>*/}
<Contact/>
</section>
);

export default ContactUs;